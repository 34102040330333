<script setup>
    import { defineEmits } from 'vue'

    const emits = defineEmits(['search'])

    function clearText() {
      document.getElementById('searchBar').value = '';
      emits('search', '');
    }
</script>

<template>
    <div class="position-relative">
      <input input="text" @keyup="function (e) { if (e.key === 'Enter') emits('search')}" id="searchBar" class="form-control border border-secondary" placeholder="Search Emails" 
        aria-label="Ask question." />
      <button @click="clearText" class="btn-close close-button position-absolute bottom-0 end-0 mb-1 me-2" aria-label="Close"></button>
    </div>
</template>

<style scoped>
  .close-button {
    width: calc(24px - .25rem);
    height: calc(24px - .25rem);
  }
</style>