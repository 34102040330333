<script setup>
  import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';
  import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
  
  // Set the worker source path for pdf.js to the provided module
  pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js';
  
  // Props
  const props = defineProps({
    show: Boolean, // To control modal visibility
    pdfUrl: String, // The PDF URL passed from App.vue
    pdfName: String,
    url: String
  });
  
  // Emits
  const emit = defineEmits(['close']);
  
  // Load PDF when component is mounted or when the URL changes
  onMounted(() => {
    loadPDF();
  });
  
  watch(() => props.pdfUrl, () => {
    loadPDF();
  });

  // Refs
  const pdfCanvas = ref(null);
  const currentPage = ref(1);
  const numPages = ref(0);

  // Function to close the modal
  function closeModal() {
    emit('close');
  }

  // Function to render a specific page using pdf.js
  async function renderPage(pageNum) {
    if (!pdfCanvas.value || !props.pdfUrl) return;

    const canvas = pdfCanvas.value;
    const context = canvas.getContext('2d');

    // Load the PDF document
    const pdf = await pdfjsLib.getDocument(props.pdfUrl).promise;
    const page = await pdf.getPage(pageNum);

    const viewport = page.getViewport({ scale: 1.5 });
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };
    await page.render(renderContext).promise;
  }

  // Function to load the PDF and set the number of pages
  async function loadPDF() {
    if (!props.pdfUrl) return;

    const pdf = await pdfjsLib.getDocument(props.pdfUrl).promise;
    numPages.value = pdf.numPages;

    // Render the first page initially
    renderPage(currentPage.value);
  }

  // Function to handle page navigation
  function nextPage() {
    if (currentPage.value < numPages.value) {
      currentPage.value++;
      renderPage(currentPage.value);
    }
  }

  function prevPage() {
    if (currentPage.value > 1) {
      currentPage.value--;
      renderPage(currentPage.value);
    }
  }
</script>

<template>
  <div class="modal-backdrop" style="opacity: .3;"></div>
  <div v-if="props.show" class="modal fade show d-block" id="pdfModal" aria-labelledby="pdfModalLabel">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <div>
              <h5 class="modal-title" id="documentModalLabel">{{ props.pdfName }}</h5>
          </div>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">                             
          <div class="pdf-controls mb-2">
            <button class="btn btn-secondary" @click="prevPage" :disabled="currentPage <= 1">Previous</button>
            <span>Page {{ currentPage }} of {{ numPages }}</span>
            <button class="btn btn-secondary" @click="nextPage" :disabled="currentPage >= numPages">Next</button>
          </div>
          <canvas ref="pdfCanvas" class="w-100"></canvas>
          <div class="url mb-3">URL: {{ props.url }}</div> 
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  /* Optional: Override Bootstrap styles or add custom styles if needed */
  .pdf-controls {
    display: flex;
    justify-content: space-between;
  }
  .url {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    background-color: #F2F2F2;
    border-radius: 5px 5px 5px 5px;
    word-wrap: break-word; 
    font-size:0.8rem;
}
</style>