<script setup>
  import { ref, onMounted } from "vue";
  import MainInbox from "./components/MainInbox.vue";
  import EmailDetails from "./components/EmailDetails.vue";
  import AISuggestedReply from "./components/AISuggestedReply.vue";
  import SearchBar from "./components/SearchBar.vue";
  import ActionsHeader from "./components/ActionsHeader.vue";
  import DocumentModal from "./components/DocumentModal.vue";
  import PDFModal from "./components/PDFModal.vue";
  import { AmazonConnectApp } from "@amazon-connect/app";
  import { AgentClient } from "@amazon-connect/contact";

  const generateGUID = function () {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }

  const env = process.env;
  const apiURL = env.VUE_APP_GEN_AI_API_HOST;
  const emailURL = env.VUE_APP_GEN_AI_EMAIL_HOST;
  const hasAccess = ref(env.VUE_APP_ENVIRONMENT == 'Development');
  const useBedrock = ref(true);

  const chatId = generateGUID();
  const agentId = ref(generateGUID());

  const loadingEmail = ref(null);
  const isLoading = ref(false);

  const selectedEmail = ref(null);
  const selectedEmailBody = ref(null);
  const selectedName = ref(null);
  const selectedSubject = ref(null);

  const blobURL = ref('');
  const files = ref({})
  const showPDFModal = ref(false);
  const showDocumentModal = ref(false);
  const currentFile = ref({
    name: '',
    html: '',
    url: '',
    type: '',
  })
  
  const emails = ref([]);
  const suggestedReply = ref(null);
  const citations = ref([]);
  const filteredEmails = ref([]);
  const suggestedReplies = ref({});

  const summaryLength = 150;

  
  onMounted(async () => {
    await (async () => {
      console.log("Environment:", env.VUE_APP_ENVIRONMENT);
      if (env.VUE_APP_ENVIRONMENT == 'Production') {
        const { provider } = await AmazonConnectApp.init({
          onCreate: async (event) => {
            const { appInstanceId } = event.context;
            console.log("App initialized: ", appInstanceId);
          },
          onDestroy: (event) => {
            console.log("App being destroyed");
          },
        });
        const agentClient = new AgentClient();
    
        let startIndex = 0;
        let endIndex = 0;
    
        const arn = await agentClient.getARN();
        console.log("Got the arn value:", arn);
    
        startIndex = arn.indexOf('instance/') + 9;
        endIndex = arn.indexOf('/agent');
    
        const instanceId = arn.substring(startIndex, endIndex);    
        console.log("InstanceId:", instanceId);
    
        startIndex = arn.indexOf('agent/') + 7;
        agentId.value = arn.substring(startIndex);
        console.log("AgentId:", agentId.value);
        
        const name = await agentClient.getName();
        console.log("Got the name:", name);
  
        if (env.VUE_APP_INSTANCE_ID == instanceId) {
          hasAccess.value = true;
        }
      }
    })();

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: headers,
      redirect: 'follow'
    };

    const response = await fetch(`${emailURL}/list-msg-files`, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('Get Email List:', error));

    const newEmails = ref([])
    
    response.forEach(email => {
      const nameEndIndex = email.from.indexOf('<') - 1 
      const emailStartIndex = email.from.indexOf('<');
      const messageId = generateGUID();

      newEmails.value.push({
        messageId: messageId,
        name: email.from.substring(0, nameEndIndex),
        email: email.from.substring(emailStartIndex),
        subject: email.subject,
        body: email.body.replaceAll('b\'', '').replaceAll('\\r\\n', '').replaceAll('\'', ''),
        summary: email.body.replaceAll('b\'', '').replaceAll('\\r\\n', '').replaceAll('\'', '').substring(0, summaryLength),
        date: new Date(email.date)
      })

      suggestedReplies.value[messageId] = {};
    })

    const historiesBody = JSON.stringify({
      chatId: `${agentId.value}`
    });

    const historiesRequestOptions = {
      method: 'POST',
      headers: headers,
      body: historiesBody,
      redirect: 'follow'
    }

    const histories = await fetch(`${apiURL}/getChatHistoryBedrock`, historiesRequestOptions);
    
    if (histories.length > 0) {
      histories.forEach(history => {
        suggestedReplies.value[history.message_id] = { 
          suggestedReply: history.response, 
          citations: history.citations 
        }
      });
    }

    newEmails.value
      .sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      })
      .forEach((email) => {
        emails.value.push(email);
        filteredEmails.value.push(email);
      });
  })

  function selectEmail(email) {
    if (selectedEmail.value == email.messageId) {
      selectedEmail.value = null;
      selectedEmailBody.value = null;
      suggestedReply.value = null;
      citations.value = [];
    } else {
      selectedEmail.value = email.messageId;
      selectedEmailBody.value = email.body;
      suggestedReply.value = suggestedReplies.value[email.messageId] !== undefined ? suggestedReplies.value[email.messageId].suggestedReply : null;
      citations.value = suggestedReplies.value[email.messageId] !== undefined ? suggestedReplies.value[email.messageId].citations : [];
      selectedName.value = email.name;
      selectedSubject.value = email.subject;
    }
  }

  async function getAISuggestedReply() {
    isLoading.value = true;
    loadingEmail.value = selectedEmail.value;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const body = JSON.stringify({
      'query': `${selectedEmailBody.value}`,
      'chatId': `${chatId}`,
      'agentId': `${agentId.value}`,
      'messageId': `${selectedEmail.value}`
    })

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: body,
      redirect: 'follow'
    }

    const response = await fetch(`${apiURL}/${useBedrock.value ? 'generateEmailBedrock' : 'generateEmail'}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("Generate Email Error", error))
    .finally(() => {
      isLoading.value = false
    });

    if (loadingEmail.value == selectedEmail.value) {
      suggestedReply.value = response.response;
      citations.value = response.citations;
    }

    suggestedReplies.value[loadingEmail.value] = {
      suggestedReply: response.response,
      citations: response.citations
    };
  }

  function searchEmails() {
    const parameters = document.getElementById("searchBar").value;
    filteredEmails.value = emails.value.filter((email) => {
      return (
        email.name.toLowerCase().includes(parameters.toLowerCase()) ||
        email.subject.toLowerCase().includes(parameters.toLowerCase()) ||
        email.body.toLowerCase().includes(parameters.toLowerCase())
      );
    });
  }

  function orderEmailsByDate(orderByDescending) {
    if (orderByDescending) {
      filteredEmails.value.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    } else {
      filteredEmails.value.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    }
  }

  function closeDocument() {
    currentFile.value.name = '';
    currentFile.value.html = '';
    currentFile.value.type = '';
    currentFile.value.url = '';
    showDocumentModal.value = false;
    showPDFModal.value = false;
  }

  async function getPDF (url) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      url: url
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
    };

    const response = await fetch(`${apiURL}/getFile`, requestOptions)
    .catch((error) => console.log("Get PDF Error:", error));

    const blob = await response.blob();

    const pdfObjectURL = URL.createObjectURL(blob);

    return pdfObjectURL;
  }

  async function getDocumentHTML (url, type) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      url: url,
      type: type
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(`${apiURL}/convertFileToHTML`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("Get Document HTML Error:", error));

    return response;
  }

  async function openDocument(url, name, type) {
    if (files.value[`${name}`] !== undefined) {
        blobURL.value = files.value[`${name}`].blobURL;
        currentFile.value = {
            name: files.value[`${name}`].name,
            html: files.value[`${name}`].html,
            url: files.value[`${name}`].url,
            type: files.value[`${name}`].type
        };
        if (type !== 'PDF'){
            showDocumentModal.value = true;
        } else {
            showPDFModal.value = true;
        }
    } else {
        currentFile.value.name = name;
        currentFile.value.type = type;
        currentFile.value.url = url;
        
        if (type !== 'PDF'){
            currentFile.value.html = await getDocumentHTML(url, type);
            showDocumentModal.value = true;
        } else {
            blobURL.value = await getPDF(url);
            showPDFModal.value = true;
        }
        
        files.value[`${name}`] = {
            name: currentFile.value.name,
            html: currentFile.value.html,
            url: currentFile.value.url,
            type: currentFile.value.type,
            blobURL: blobURL.value,
        }
    }
  }
</script>

<template>
  <template v-if="hasAccess">
    <div class="main-container container-fluid">
      <div class="main-row row">
        <div class="col-xl-4 col-lg-6">
          <SearchBar 
            class="my-3 ms-3"
            @search="searchEmails"
          />
          <div class="main-inbox">
            <MainInbox
              :emails="filteredEmails"
              :selectedEmail="selectedEmail"
              @selectEmail="selectEmail"
              @orderEmailsByDate="orderEmailsByDate"
            />
          </div>
        </div>
        <div class="col-xl-8 col-lg-6 border-start">
          <div>
            <ActionsHeader
              v-if="selectedEmailBody != null"
              :email="selectedEmailBody"
              :suggestedReply="suggestedReply"
              :isLoading="isLoading.valueOf()"
              @getAISuggestedReply="getAISuggestedReply"
            />
          </div>
          <div class="email-details">
            <AISuggestedReply
              v-if="suggestedReply != null"
              class="border-bottom pb-3"
              :suggestedReply="suggestedReply.valueOf()"
              :citations="citations"
              @openDocument="openDocument"
            />
            <EmailDetails
              v-if="selectedEmailBody != null"
              :body="selectedEmailBody.valueOf()"
              :name="selectedName.valueOf()"
              :subject="selectedSubject.valueOf()"
            />
            <DocumentModal 
              v-if="showDocumentModal"
              :show="showDocumentModal.valueOf()"
              :html="currentFile.html"
              :url="currentFile.url"
              :name="currentFile.name"
              @close="closeDocument"
            />
            <PDFModal
              v-if="showPDFModal"
              :show="showPDFModal"
              :pdfUrl="blobURL" 
              :url="currentFile.url"
              :pdfName="currentFile.name"
              @close="closeDocument" 
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-if="!hasAccess">
    <div class="w-100 mt-5 pt-5">
      <h1 class="text-center">You do not have access</h1>
    </div>
  </template>
</template>

<style>
  html {
    height: 100%;
  }
  body {
    height: 100%;
  }
  #app {
    font-family: "PT Sans", "sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    height: 100%;
    width: 100%;
    border: 1px solid lightgray;
  }
  .main-container {
    height: 100%;
  }
  .main-row {
    height: 100%;
  }
  @media (max-width: 991px) {
    .main-container {
      height: 50%;
    }
    .main-row {
      height: 50%;
    }
    .email-details {
      max-height: calc(40vh * sin(3.14 / 2)) !important;
      overflow-y: auto;
    }
  }
  .main-inbox {
    overflow-y: auto;
  }
  .email-details {
    max-height: calc(85vh * sin(3.14 / 2));
    overflow-y: auto;
  }
</style>
