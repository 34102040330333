<script setup>
    import { defineProps } from 'vue';

    const props = defineProps(['name', 'email', 'subject', 'body', 'date', 'summary'])

    const formattedDate = `${props.date.getMonth() + 1}/${props.date.getDate()}/${props.date.getFullYear()}`;
    const hours = props.date.getHours() >= 10 ? props.date.getHours() : '0' + props.date.getHours();
    const minutes = props.date.getMinutes() >= 10 ? props.date.getMinutes() : '0' + props.date.getMinutes();
    const meridian = hours >= 12 ? 'pm' : 'am'
    const formattedTime = `${hours % 12}:${minutes}${meridian}`;
</script>

<template>
    <div class="d-flex justify-content-left mb-3">
        <div class="flex-grow-1">
            <span class="name me-1">
                {{ props.name }}
            </span>
            <div class="subject mt-1">
                <strong>{{ props.subject }}</strong>
            </div>
            <div v-html="props.summary">
            </div>
        </div>
        <div class="d-flex flex-column mt-1">
            <div class="mb-2">{{ formattedDate }}</div>
            <div>{{ formattedTime }}</div>
        </div>
    </div>
</template>

<style scoped>
    .subject {
        font-size: 16px;
    }
    .name {
        font-size: 18px;
    }
</style>