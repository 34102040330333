<script setup>
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-chrevron-up arrow-up" :viewBox="'0 0 28 16'">
        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
    </svg>
</template>

<style>
    .arrow-up {
        width: 20px;
        height: 20px;
        fill: currentColor;
    }
</style>