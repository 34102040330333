<script setup>
    import { defineProps, defineEmits } from 'vue';
    import LoadingSpinner from './LoadingSpinner.vue';

    const props = defineProps(['name', 'isLoading'])
    const emits = defineEmits(['action'])
</script>

<template>
    <button class="btn btn-outline-primary compose-button" @click="emits('action')">
        <div class="d-flex">
            <span>{{ props.name }}</span>
            <span class="ms-2 pt-1">
                <LoadingSpinner 
                    v-if="props.isLoading"
                />
            </span>
        </div>
    </button>
</template>

<style>
    .compose-button {
        color: #087599ff !important;
        border: 2px solid #087599ff;
        border-radius: 15px 15px 15px 15px;
        font-weight: bold;
    }
    .compose-button:hover {
        background-color: rgb(8, 117, 153, .1) !important;
        border-color: #087599ff !important;
    }
    .compose-button:focus {
        background-color: rgb(8, 117, 153, .1) !important;
        border-color: #087599ff !important;
    }
</style>