<script setup>
    import { defineProps, defineEmits } from 'vue';
    import AIComposeButton from './AIComposeButton.vue';
    import SendEmailButton from './SendEmailButton.vue';

    const props = defineProps(['email', 'suggestedReply', 'isLoading'])
    const emits = defineEmits(['getAISuggestedReply'])

    function sendEmail(email) {
        console.log(`Send: ${email}`);
    }
</script>

<template>
    <div class="d-flex action-header">
        <AIComposeButton
            v-if="props.suggestedReply == null"
            class="mb-3"
            :name="'Generate Reply'"
            :isLoading="props.isLoading"
            @action="emits('getAISuggestedReply')"
        />
        <SendEmailButton 
            v-if="props.suggestedReply != null"
            class="mb-3"
            @sendEmail="sendEmail"
        />
    </div>
</template>

<style>
    .action-header {
        margin-top: 0.9rem;
    }
</style>