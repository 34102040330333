<script setup>
</script>

<template>
    <div class="d-flex align-items-center">
        <div class="spinner-border spinner-border-sm loading-spinner" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<style>
    .loading-spinner {
        color: #087599ff !important;
    }
</style>