<script setup>
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-chrevron-down arrow-down" :viewBox="'0 0 28 16'">
        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
    </svg>
</template>

<style>
    .arrow-down {
        width: 20px;
        height: 20px;
        fill: currentColor;
    }
</style>