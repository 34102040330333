<script setup>
    import { defineEmits, defineProps } from 'vue';
    
    const props = defineProps(['html', 'url', 'name', 'show'])
    const emits = defineEmits(['close'])
</script>

<template>
    <div class="modal-backdrop" style="opacity: .3;"></div>
    <div v-if="props.show" class="modal fade show d-block" id="documentModal" aria-labelledby="documentModalLabel">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h5 class="modal-title" id="documentModalLabel">{{ props.name }}</h5>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="documentModal" aria-label="Close" @click="emits('close')"></button>
                </div>
                <div class="modal-body mt-1">                    
                    <div v-html="props.html"></div>
                    <div class="url">URL: {{ props.url }}</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="documentModal" @click="emits('close')">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .url {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        background-color: #F2F2F2;
        border-radius: 5px 5px 5px 5px;
        word-wrap: break-word; 
        font-size:0.8rem;
    }
</style> 