<script setup>
    import { defineProps } from 'vue';

    const props = defineProps(['body', 'name', 'subject'])
</script>

<template>
    <div class="p-1">
        <h4 class="mb-3">{{ props.subject }}</h4>
        <h5 class="mb-3">{{ props.name }}</h5>
        <div>
            {{ props.body }}
        </div>
    </div>
</template>

<style>
</style>