<script setup>
    import { defineEmits } from 'vue';
    import SendArrow from '@/Icons/SendArrow.vue';

    const emits = defineEmits(['sendEmail']);

    function sendEmail() {
        const element = document.getElementById('email-composition');
        const email = element.innerHTML;
        emits('sendEmail', email);
    }
</script>

<template>
    <button @click="sendEmail" class="btn btn-outline-primary send-button">
        <div class="d-flex">
            <span>Send Email</span>
            <span class="ms-2">
                <SendArrow />
            </span>
        </div>
    </button>
</template>

<style>
    .send-button {
        color: #087599ff !important;
        border: 2px solid #087599ff;
        border-radius: 15px 15px 15px 15px;
        font-weight: bold;
    }
    .send-button:hover {
        background-color: rgb(8, 117, 153, .1) !important;
        border-color: #087599ff !important; 
    }
    .send-button:focus {
        background-color: rgb(8, 117, 153, .1) !important;
        border-color: #087599ff !important;
    }
</style>