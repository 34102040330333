<script setup>
    import { defineProps, defineEmits, watch, onMounted } from 'vue';

    const props = defineProps(['suggestedReply', 'citations']);
    const emits = defineEmits(['openDocument']);

    onMounted(() => {
        setLinkListeners();
    })

    watch(() => props.suggestedReply, async () => {
        await new Promise(r => setTimeout(r, 250));
        setLinkListeners();
    })

    function setLinkListeners() {
        const anchorTags = document.querySelectorAll('.open-modal');
        anchorTags.forEach(tag => {
            tag.addEventListener('click', (event) => {
                event.preventDefault();
                
                const clickAttr = tag.getAttribute('@click');
                const regex = /openDocument\('(.+?)','(.+?)','(.+?)'\)/;
                const match = clickAttr.match(regex);

                if (match) {
                    const url = match[1];
                    const name = match[2];
                    const type = match[3];
                    emits('openDocument', url, name, type);
                }
            });
        });
    }
</script>

<template>
    <div class="p-1">
        <h4 class="mb-3">AI Suggested Reply</h4>
        <div v-html="props.suggestedReply" class="compose-email mb-3" id="email-composition">
        </div>
        <ol class="my-3">
            <li v-bind:key="`citation-${i}`" v-for="(citation,i) in props.citations">
                <a :key="'link-' + i" v-if="citation.name.toLowerCase().includes('.docx') || citation.url.toLowerCase().includes('.docx')" href="#" data-bs-toggle="modal" target="#documentModal" @click="emits('openDocument', citation.url, citation.name, 'DOC')">{{ citation.name }}</a>
                <a :key="'link-' + i" v-if="citation.name.toLowerCase().includes('.txt') || citation.url.toLowerCase().includes('.txt')" href="#" data-bs-toggle="modal" target="#documentModal" @click="emits('openDocument', citation.url, citation.name, 'TXT')">{{ citation.name }}</a>
                <a :key="'link-' + i" v-if="citation.name.toLowerCase().includes('.pdf') || citation.url.toLowerCase().includes('.pdf')" href="#" data-bs-toggle="modal" target="#documentModal" @click="emits('openDocument', citation.url, citation.name, 'PDF')">{{ citation.name }}</a>
                <a :key="'link-' + i" v-if="!citation.name.toLowerCase().includes('.docx') && !citation.name.toLowerCase().includes('.pdf') && !citation.name.toLowerCase().includes('.txt') && !citation.url.toLowerCase().includes('.docx') && !citation.url.toLowerCase().includes('.txt') && !citation.url.toLowerCase().includes('.pdf')" :href="citation.url" target="_blank" class="ms-2 mb-3">{{ citation.name }}</a>                            
            </li>
        </ol>
    </div>
</template>

<style>
    .compose-email {
        width: 100%;
    }
</style>