<script setup>
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-send-fill send" viewBox="0 0 26 22">
        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"/>
    </svg>
</template>

<style>
    .send {
        width: 22px;
        height: 22px;
        fill: #087599ff;
    }
</style>