<script setup>
    import { defineEmits, defineProps, ref } from 'vue';
    import InboxItem from './InboxItem.vue';
    import ArrowUp from '../Icons/ArrowUp.vue';
    import ArrowDown from '../Icons/ArrowDown.vue';
    
    const emits = defineEmits(['selectEmail', 'orderEmailsByDate'])
    const props = defineProps(['emails', 'selectedEmail'])

    const orderByDescending = ref(true);

    function toggleDescending() {
        orderByDescending.value = !orderByDescending.value;
        emits('orderEmailsByDate', orderByDescending.value);
    }
</script>

<template>
    <div class="d-flex align-items-center py-2 ps-3 border-bottom">
        <div class="d-flex flex-grow-1">
            <!--<img class="me-2 pb-1" src="../assets/axyom-favicon.png" wdith="33px" height="33px" />-->
            <h4>Axyom Assist Inbox</h4>
        </div>
        <div class="d-flex order-date"
            @click="toggleDescending"
        >
            <h6>Date</h6>
            <span>
                <ArrowDown 
                    v-if="orderByDescending"
                    class="ms-1 pb-1"
                />
                <ArrowUp 
                    v-if="orderByDescending == false"
                    class="ms-1 pb-1"
                />
            </span>
        </div>
    </div>
    <ul class="inbox-list list-group list-group-flush">
        <button 
            v-bind:key="`inbox-item-${email.messageId}`" 
            v-for="email in props.emails" 
            :class="`inbox-item list-group-item ${email.messageId == props.selectedEmail ? 'active' : ''}`"
            @click="emits('selectEmail', email)"    
        >
            <InboxItem 
                :name="email.name"
                :email="email.email"
                :subject="email.subject"
                :body="email.body"
                :summary="email.summary"
                :date="email.date"
            />
        </button>
    </ul>
</template>

<style>
    .active {
        background-color: #eff6ff !important;
        border-color: #cfe2ff !important;
        color: black !important;
    }
    @media (max-width: 991px) {
        .inbox-list {
            max-height: calc(40vh * sin(3.14 / 2)) !important;
            overflow-y: auto;
            border-bottom: 1px solid lightgray;
        }
    }
    .inbox-list {
        max-height: calc(85vh * sin(3.14 / 2));
        overflow-y: auto;
    }
    .inbox-item {
        cursor: pointer;
        text-align: left;
    }
    .inbox-item:hover {
        background-color: #f8f9fa;
    }
    .inbox-item:focus {
        background-color: #f8f9fa;
    }
    .order-date {
        cursor: pointer;
    }
    .order-date:hover {
        text-decoration: underline;
    }
</style>